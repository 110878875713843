<template>
  <v-form ref="formElem">
    <translation-locales />

    <v-card>
      <v-card-text>
        <translatable-input
          v-model="form.name"
          label="Key"
          outlined
          :rules="[required]"
          class="mt-5"
        />
        <v-text-field
          v-model="form.code"
          label="Key"
          outlined
          :rules="[required]"
          class="mt-5"
        />

        <v-switch
          v-model="form.active"
          color="primary"
          true-value="active"
          false-value="obsoleted"
          :label="form.status ? 'Active' : 'Inactive'"
        ></v-switch>
      </v-card-text>

      <v-file-input
        v-model="form.logo"
        :loading="loading"
        accept="image/*"
        label="Choose Image for Logo"
      >
      </v-file-input>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { TranslatableInput, TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { required } from '@core/utils/validation'
import { } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  components: {
    TranslatableInput,
    TranslationLocales,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    // Get value from resource prop, use initValue if not exist
    const field = (name, initValue) => ({
      [name]: props.resource ? props.resource[name] : initValue,
    })

    const rules = props.resource ? props.resource.rules : false

    const initialForm = {
      ...field('name', useTranslatable(null)),
      ...field('active', true),
      ...field('code', null),
      ...field('logo', null),
    }

    const form = ref({
      ...initialForm,
    })
    const formElem = ref(null)

    const typeOptions = ref([
      {
        name: 'Active',
        value: 'active',
      },
      {
        name: 'Obsoleted',
        value: 'obsoleted',
      },
    ])

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    return {
      form,
      formElem,
      validate,
      required,

      typeOptions,
    }
  },
}
</script>
