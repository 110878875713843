var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formElem"
  }, [_c('translation-locales'), _c('v-card', [_c('v-card-text', [_c('translatable-input', {
    staticClass: "mt-5",
    attrs: {
      "label": "Key",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('v-text-field', {
    staticClass: "mt-5",
    attrs: {
      "label": "Key",
      "outlined": "",
      "rules": [_vm.required]
    },
    model: {
      value: _vm.form.code,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "code", $$v);
      },
      expression: "form.code"
    }
  }), _c('v-switch', {
    attrs: {
      "color": "primary",
      "true-value": "active",
      "false-value": "obsoleted",
      "label": _vm.form.status ? 'Active' : 'Inactive'
    },
    model: {
      value: _vm.form.active,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  })], 1), _c('v-file-input', {
    attrs: {
      "loading": _vm.loading,
      "accept": "image/*",
      "label": "Choose Image for Logo"
    },
    model: {
      value: _vm.form.logo,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "logo", $$v);
      },
      expression: "form.logo"
    }
  }), _c('v-card-actions', {
    staticClass: "justify-center"
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.validate
    }
  }, [_vm._v(" Submit ")])], 1), _vm._l(_vm.errors, function (msg) {
    return _c('v-alert', {
      key: msg,
      attrs: {
        "color": "warning"
      }
    }, [_vm._v(" " + _vm._s(msg) + " ")]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }